<template>
  <vs-popup class="sm:popup-w-90 popup-content-no-padding" title="Pilih Item Pekerjaan" :active="active" v-on:update:active="emitModalIsActive">
    <div id="email-app" class="flex relative overflow-hidden">

      <!----------- BOX MASTER ------------->
      <div class="w-full sm:w-6/12">
        <!--search-->
        <div class="no-scroll-content border border-solid d-theme-border-grey-light border-r-0 border-t border-b-0">
          <div class="flex border d-theme-dark-bg items-center">
            <vs-input v-model="boxMaster.search" icon-no-border icon="icon-search" size="large" icon-pack="feather" placeholder="Cari Item" class="vs-input-no-border vs-input-no-shdow-focus w-full"/>
          </div>
          <!--action bar-->
          <div class="email__actions flex items-center flex-wrap justify-between p-4 border border-r-0 border-l-0 border-solid d-theme-border-grey-light">
            <div class="flex items-center">
              <vs-checkbox :value="checkedCountBoxMaster" @input="onCheckAllBoxMaster" icon-pack="feather" :icon="checkedCountBoxMaster === (boxMaster.items.length - boxBasket.items.length) ? 'icon-check' : 'icon-minus'" class="select-all-chexkbox ml-0">Pilih Semua</vs-checkbox>
              <span class="text-xs text-grey-dark">({{ checkedCountBoxMaster }} of {{ boxMaster.items.length - boxBasket.items.length }} checked)</span>
            </div>
            <div class="flex">
              <feather-icon @click="getItemProgressPekerjaan" icon="RefreshCwIcon" class="cursor-pointer ml-5" svg-classes="h-6 w-6"></feather-icon>
              <router-link :to="{ name: 'master.itemProgressPekerjaan'}" target="_blank" class="items-center flex">
                <feather-icon icon="PlusIcon" class="cursor-pointer ml-5 text-dark" svg-classes="h-6 w-6"></feather-icon>
              </router-link>
              <vs-button @click="moveToBoxBasket" color="success" class="ml-5" type="filled" size="small" icon-pack="feather" icon="icon-chevrons-right" :disabled="checkedCountBoxMaster === 0"></vs-button>
            </div>
          </div>
          <!--list-->
          <component :is="scrollbarTag" class="email-content-scroll-area" :settings="scrollbarSettings" ref="mailListPS">
            <div class="w-full h-full flex items-center" v-if="boxMaster.loading || boxMasterItems.length === 0">
              <span class="w-full text-center">{{ boxMaster.loading ? 'Loading...' : 'Tidak ada data.' }}</span>
            </div>
            <transition-group name="list-enter-up" class="email__mails" tag="ul" appear>
              <li class="cursor-pointer email__mail-item" v-for="(item) in boxMasterItems" :key="item.id" @click="item.checked = !item.checked">
                <div class="mail__mail-item sm:px-4 px-2 py-4 mail__opened-mail">
                  <div class="flex w-full">
                    <div class="flex ml-0 mr-1 items-center" @click.stop>
                      <vs-checkbox v-model="item.checked" class="vs-checkbox-small align-middle"/>
                    </div>
                    <div class="flex w-full justify-between items-start">
                      <div class="mail__details">
                        <h5 class="mb-1">{{ item.nama }}</h5>
                        <span class="text-xs bg-ema opacity-75">{{ itemGroupText(item) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </transition-group>
          </component>
        </div>
      </div>

      <!----------- BOX BASKET ------------->
      <div class="w-full sm:w-6/12">
        <div class="no-scroll-content border border-solid d-theme-border-grey-light border-r-0 border-t border-b-0">
          <!--search-->
          <div class="flex border d-theme-dark-bg items-center">
            <vs-input v-model="boxBasket.search" icon-no-border icon="icon-search" size="large" icon-pack="feather" placeholder="Cari Item" class="vs-input-no-border vs-input-no-shdow-focus w-full"/>
          </div>
          <!--action bar-->
          <div class="email__actions flex items-center flex-wrap justify-between p-4 border border-r-0 border-l-0 border-solid d-theme-border-grey-light">
            <div class="flex items-center">
              <vs-checkbox :value="checkedCountBoxBasket" @input="onCheckAllBoxBasket" icon-pack="feather" :icon="checkedCountBoxBasket === boxBasket.items.length ? 'icon-check' : 'icon-minus'" class="select-all-chexkbox ml-0">Pilih Semua</vs-checkbox>
              <span class="text-xs text-grey-dark">({{ checkedCountBoxBasket }} of {{ boxBasket.items.length }} checked)</span>
              <span class="text-xs text-grey-dark mx-2">|</span>
              <span class="text-xs text-grey-dark">(Bobot {{ totalBobot }}/100)</span>
            </div>
            <div class="flex items-center">
              <vs-button @click="moveToBoxMaster" color="danger" size="small" type="filled" icon-pack="feather" icon="icon-chevrons-left" :disabled="checkedCountBoxBasket === 0"></vs-button>
            </div>
          </div>
          <div class="con-vs-alert mb-3 con-vs-alert-danger rounded-none" v-if="totalBobot !== 100 && this.boxBasket.items.length > 0">
            <div class="vs-alert">
              <p class="font-semibold text-sm">Total bobot harus 100%.
                <span v-if="100 - totalBobot > 0">Kurang {{ 100 - totalBobot }}%.</span>
                <span v-if="100 - totalBobot < 0">Lebih {{ Math.abs(100 - totalBobot) }}%.</span>
              </p>
            </div>
          </div>

          <!--list-->
          <component :is="scrollbarTag" class="email-content-scroll-area" :settings="scrollbarSettings" ref="mailListPS">
            <div class="w-full h-full flex items-center" v-if="boxBasketItems.length === 0">
              <span class="w-full text-center">Tidak ada data.</span>
            </div>
            <Draggable class="cursor-move p-0" :list="boxBasket.items" :animation="100" @change="onDraggedBoxBasket" @start="boxBasket.drag = true" @end="boxBasket.drag = false">
              <transition-group :name="!boxBasket.drag ? 'flip-list' : null" class="email__mails" tag="ul">
                <li class="cursor-pointer email__mail-item" v-for="item in boxBasketItems" :key="item.id" @click="item.checked = !item.checked">
                  <div class="mail__mail-item sm:px-4 px-2 py-4 mail__opened-mail">
                    <div class="flex w-full">
                      <div class="flex ml-0 mr-1 items-center" @click.stop>
                        <vs-checkbox v-model="item.checked" class="vs-checkbox-small align-middle"/>
                      </div>
                      <vs-avatar class="sender__avatar flex-shrink-0 mr-3" :text="item.no_urut.toString()" size="30px"/>
                      <div class="flex w-full sm:w-10/12 justify-between items-start">
                        <div class="mail__details">
                          <h5 class="mb-1">{{ item.nama }}</h5>
                          <span class="text-xs bg-ema">Bobot: {{ item.bobot }}%</span>
                          <span class="text-xs bg-ema ml-3" v-if="item.sharing">|</span>
                          <span class="text-xs bg-ema ml-3 text-success" v-if="item.sharing">SHARING</span>
                          <br>
                          <span class="text-xs opacity-75 bg-ema">{{ itemGroupText(item) }}</span>
                        </div>
                      </div>
                      <div class="flex w-full sm:w-2/12 justify-end">
                        <feather-icon
                          :icon="item.showEdit ? 'CheckIcon' : 'EditIcon'"
                          class="cursor-pointer"
                          svgClasses="text-warning stroke-current w-5 h-5"
                          @click.stop="item.showEdit = !item.showEdit"/>
                      </div>
                    </div>
                    <div v-if="item.showEdit" @click.stop>
                      <vs-divider color="warning">Edit Area</vs-divider>
                      <div class="vx-row">
                        <div class="vx-col sm:w-6/12 w-full">
                          <label class="ml-1 text-xs">Bobot %</label>
                          <vs-input class="w-full" type="number" v-model="item.bobot"/>
                        </div>
                        <div class="vx-col sm:w-6/12 w-full">
                          <label class="ml-1 text-xs"></label>
                          <vs-checkbox color="success" v-model="item.sharing">Sharing</vs-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </transition-group>
            </Draggable>
          </component>
        </div>
      </div>
    </div>
  </vs-popup>
</template>

<script>
import Draggable from 'vuedraggable'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import _ from 'lodash'
import ItemProgressPekerjaanRepository from '@/repositories/master/item-progress-pekerjaan-repository'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import constant from '@/constant/constant'

export default {
  name: 'ItemsPekerjaanChooser',
  props: ['active', 'rowUuid'],
  components: {
    VuePerfectScrollbar,
    Draggable,
    flatPickr
  },
  watch: {
    active (newVal, oldVal) {
      if (newVal === true) {
        // init items from tab pekerjaan where row uuid = x
        this.boxBasket.items = _.cloneDeep(this.row.itemsPekerjaan)
        this.boxBasket.deletedIds = []

        // request new data
        this.getItemProgressPekerjaan()
      }
    }
  },
  computed: {
    tabHeader () {
      return this.$store.state.proyek.spkAdd.tabHeader
    },
    jenisSpk () {
      return this.tabHeader.jenis_spk
    },
    row () {
      return this.$store.getters['proyek/spkAdd/getTabPekerjaanItemByRowUuid'](this.rowUuid)
    },
    scrollbarTag () {
      return this.$store.getters['theme/scrollbarTag']
    },
    scrollbarSettings () {
      return { maxScrollbarLength: 100, wheelSpeed: 0.50 }
    },
    boxMasterItems () {
      const items = _.differenceBy(this.boxMaster.items, this.boxBasket.items, 'id')
      const search = this.boxMaster.search
      if (search) {
        return _.filter(items, item => item.nama.toLowerCase().includes(search.toLowerCase()))
      }
      return items
    },
    boxBasketItems () {
      const items = this.boxBasket.items
      const search = this.boxBasket.search
      if (search) {
        return _.filter(items, item => item.nama.toLowerCase().includes(search.toLowerCase()))
      }
      return items
    },
    checkedCountBoxMaster () {
      return _.filter(this.boxMaster.items, item => item.checked === true).length
    },
    checkedCountBoxBasket () {
      return _.filter(this.boxBasket.items, item => item.checked === true).length
    },
    totalBobot () {
      return _.sumBy(this.boxBasket.items, item => (parseFloat(item.bobot) || 0))
    }
  },
  data () {
    return {
      boxMaster: {
        search: null,
        loading: true,
        items: []
      },
      boxBasket: {
        search: null,
        drag: false,
        items: [],
        deletedIds: []
      }
    }
  },
  methods: {
    getItemProgressPekerjaan () {
      this.boxMaster.items = []
      this.boxMaster.loading = true

      const params = {
        jenis_spk: this.jenisSpk,
        id_proyek: this.tabHeader.id_proyek
      }

      // for edit
      if (this.$route.params.idSpk) {
        params.ignore_id_spk = this.$route.params.idSpk
      }

      if (this.jenisSpk === constant.JENIS_SPK.UNIT) {
        params.id_properti_tipe = this.row.id_properti_tipe
        params.id_properti_unit = this.row.id_properti_unit
      }

      ItemProgressPekerjaanRepository.getItemsForSpk(params)
        .then(response => {
          this.boxMaster.items = _.map(response.data.data, item => {
            item.checked = false
            return item
          })
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.boxMaster.loading = false
        })
    },

    moveToBoxBasket () {
      const checkedItems = _.filter(this.boxMaster.items, item => item.checked)
      _.map(checkedItems, item => {
        item.checked = false
      })

      const checkedItemsCloned = _.cloneDeep(checkedItems)
      _.map(checkedItemsCloned, item => {
        item.showEdit = false
      })

      const currentBoxBasketItems = this.boxBasket.items
      this.boxBasket.items = [...currentBoxBasketItems, ...checkedItemsCloned]
      this.updateSortBoxBasket()
    },

    moveToBoxMaster () {
      const notCheckedItems = _.filter(this.boxBasket.items, item => !item.checked)
      const checkedItems = _.filter(this.boxBasket.items, item => item.checked)
      this.boxBasket.items = notCheckedItems
      this.updateSortBoxBasket()
      const deletedIds = _.map(checkedItems, item => {
        if (item.old_id_spk_pekerjaan) return item.old_id_spk_pekerjaan
      })
      this.saveDeletedIds(deletedIds)
    },

    saveDeletedIds (ids) {
      this.boxBasket.deletedIds = _.union(this.boxBasket.deletedIds, ids)
    },

    onCheckAllBoxMaster (isCheckAll) {
      this.boxMasterItems.forEach(item => {
        item.checked = isCheckAll
      })
    },

    onCheckAllBoxBasket (isCheckAll) {
      this.boxBasketItems.forEach(item => {
        item.checked = isCheckAll
      })
    },

    onDraggedBoxBasket () {
      this.updateSortBoxBasket()
    },

    updateSortBoxBasket () {
      this.boxBasket.items.forEach((item, index) => {
        item.no_urut = index + 1
      })
    },

    emitModalIsActive (isActive) {
      if (!isActive) {
        this.emitOnSelected()
      }
      this.$emit('update:active', isActive)
    },

    emitOnSelected () {
      const data = {
        items: this.boxBasket.items,
        deletedIds: this.boxBasket.deletedIds
      }
      this.$emit('selected', data)
    },

    itemGroupText (item) {
      const group1 = item.item_group1 ? item.item_group1.nama : 'null'
      const group2 = item.item_group2 ? item.item_group2.nama : 'null'
      const group3 = item.item_group3 ? item.item_group3.nama : 'null'
      const group4 = item.item_group4 ? item.item_group4.nama : 'null'
      return [group1, group2, group3, group4].join(' | ')
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/vuexy/apps/email.scss";
</style>
